import * as React from "react"
import {
  Button, ClickAwayListener,
  createStyles,
  Grow, Link,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Theme
} from "@material-ui/core"
import { References } from "./header-right"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    paper: {
      marginRight: theme.spacing(2)
    },
    button: {
      color: 'white',
      textTransform: 'none',
      fontSize: 14,
      fontWeight: "bold",
      minWidth: 145,
      zIndex: 10,
    }
  })
)

const ReferencesDropdown = () => {
  const classes = useStyles()

  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLButtonElement>(null)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault()
      setOpen(false)
    }
  }

  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus()
    }

    prevOpen.current = open
  }, [open])

  return <div className={classes.root}>
    <Button
      ref={anchorRef}
      aria-controls={open ? "menu-list-grow" : undefined}
      aria-haspopup="true"
      onClick={handleToggle}
      className={classes.button}
    >
      Referenciák
    </Button>
    <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{zIndex: 9999}}>
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClose} >
              <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                {
                  References.map(r => {
                    return <Link href={r.url} underline="none">
                      <MenuItem onClick={handleClose}>
                        {r.name}
                      </MenuItem>
                    </Link>
                  })
                }
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  </div>
}

export default ReferencesDropdown
