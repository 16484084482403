import * as React from "react"
import { Link, makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
    link: {
      color: 'white',
      "&:hover": {
        color: theme.palette.primary.main,
      },
    }
}));

const FooterLink = ({name, link}) => {
  const classes = useStyles();
  return <Link href={link} underline={'none'} className={classes.link}>
    <div>
      {name}
    </div>
  </Link>
}

export default FooterLink
