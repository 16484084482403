import * as React from "react"
import { Button, Link, makeStyles } from "@material-ui/core"
import AlphaButton from "../alpha-button/alpha-button"

const useStyles = makeStyles((theme) => ({
  link: {
    color: "white",
  },
  button: {
    textTransform: 'none',
    fontSize: 12,
    fontWeight: 'bold',
    zIndex: 10,
  }
}))

const KapcsolatButton = () => {
  const classes = useStyles()
  return <Link href="/kapcsolat" underline="none" className={classes.link}>
    <AlphaButton className={classes.button} variant="contained">
      Kapcsolat
    </AlphaButton>
  </Link>
}

export default KapcsolatButton
