import * as React from "react"
import {
  Button,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  makeStyles,
  Menu,
  MenuItem,
  SwipeableDrawer, useMediaQuery
} from "@material-ui/core"
import KapcsolatButton from "./kapcsolat-button"
import MenuIcon from "@material-ui/icons/Menu"
import { Link } from "gatsby"
import ReferencesDropdown from "./references-dropdown"
import ServicesDropdown from "./services-dropdown"
import { useEffect, useState } from "react"
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { WebshopFunctionInterface } from "../../interfaces/webshop-function-interface/webshop-function-interface"

const useStyles = makeStyles((theme) => ({
  link: {
    color: "white",
    textDecoration: "none",
    fontFamily: "Poppins",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    cursor: "pointer",
    fontSize: 14,
    fontWeight: "bold",
    "&:hover": {
      background: "rgba(75, 75, 75, 0.2)"
    },
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
    zIndex: 10,
    [theme.breakpoints.down(900)]: {
      display: "none"
    },
    minWidth: 145
  },
  mobileLink: {
    textDecoration: "none",
    color: "black",
    "&:active": {
      color: "black"
    }
  },
  divButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down(900)]: {
      display: "none"
    },
    paddingLeft: 10,
    paddingRight: 10
  },
  mobileMenu: {
    display: "none",
    color: "white",
    [theme.breakpoints.down(900)]: {
      display: "block"
    },
    zIndex: 100
  },
  listItem: {
    width: 280
  },
  grow: {
    flex: 1
  },
  collapseButton: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  divCollapseElement: {
    color: theme.palette.primary.main
  },
  collapse: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  drawer: {
    [theme.breakpoints.up(900)]: {
      display: "none"
    },
    zIndex: 101
  }
}))

const LINKS = [
  { url: "/bemutatkozas", label: "Rólunk" },
  { url: "/webshop-funkcioi", label: "Funkciók" }
  //{ url: "/hirek", label: "Hírek" }
]

const MOBILE_LINKS = [
  ...LINKS,
  { url: "/kapcsolat", label: "Kapcsolat" }
]

export const Services = [
  { name: "Webshop bérlés", url: "/webshop-berles" },
  { name: "Webshop üzemeltetés", url: "/webshop-uzemeltetes" },
  { name: "Egyedi fejlesztések", url: "/egyedi-fejlesztesek" },
  { name: "Egyedi webshop dizájn", url: "/egyedi-webshop-dizajn" },
  { name: "Applikáció fejlesztés", url: "/applikacio-fejlesztes" },
  { name: "Hibafeltárás", url: "/hibafeltaras" },
  { name: "Seo Audit", url: "/seo-audit" },
]

export const References = [
  { name: 'Play IT Store', url: "/play-it-store"},
  { name: 'Office Depot', url: "/office-depot"},
  { name: 'Asztalra.hu', url: "/asztalra"},
  { name: 'Netlampa', url: "/netlampa"},
  { name: 'Elit Safety', url: "/elitsafety"},
  { name: 'Power Life', url: "/power-life"},
  { name: 'Eventure', url: "/eventure"},
  { name: 'PlayIT', url: "/play-it"},
  { name: 'Budapest Comic Con', url: "/budapestcomiccon"},
  { name: 'Snowattack', url: "/snowattack"},
  { name: 'Arcedula', url: "/arcedula"},
  { name: 'Festival Travel', url: "/festivaltravel"},
]

const HeaderRight = () => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const matches = useMediaQuery('(max-width:900px)');

  useEffect(() => {
    setDrawerOpen(false)
  }, [matches])

  const toggleDrawer = (open: boolean) => {
    setDrawerOpen(open)
  }

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    console.log("But why why why ")
    if (Boolean(anchorEl)) {
      setAnchorEl(null)
    }
  }

  return <>
    {LINKS.map((link, i) => (
      <Link to={link.url} className={classes.link} key={i}>
        {link.label}
      </Link>
    ))}
    {!matches && <ServicesDropdown />}
    {!matches && <ReferencesDropdown />}
    <div className={classes.divButton}>
      <KapcsolatButton />
    </div>

    {/*<IconButton className={classes.mobileMenu} onClick={handleMenuClick}>
      <MenuIcon />
    </IconButton>
    <Menu
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      keepMounted
      onClose={handleCloseMenu}
    >
      {MOBILE_LINKS.map((link, i) =>
        <MenuItem key={i}>
          <Link to={link.url} className={classes.mobileLink}>{link.label}</Link>
        </MenuItem>
      )}
    </Menu>*/}

    <IconButton className={classes.mobileMenu} onClick={() => toggleDrawer(true)} aria-label={'Menu'}>
      <MenuIcon />
    </IconButton>
    <SwipeableDrawer
      anchor={"right"}
      open={drawerOpen}
      onClose={() => toggleDrawer(false)}
      onOpen={() => toggleDrawer(true)}
      className={classes.drawer}
    >
      <List>
        {MOBILE_LINKS.map((link, i) =>
          <Link to={link.url} className={classes.mobileLink} key={i}>
            <ListItem button key={i} className={classes.listItem}>
              {link.label}
            </ListItem>
          </Link>
        )}
        <ButtonCollapse name="Szolgáltatások" array={Services}/>
        <ButtonCollapse name="Referenciák" array={References}/>
      </List>
    </SwipeableDrawer>
  </>
}

interface CollapseProps {
  name: string;
  array: any[]
}

const ButtonCollapse = (props: CollapseProps) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  return <ListItem button className={classes.listItem} style={{ display: "block"}}>
    <div className={classes.collapseButton} onClick={() => setOpen(!open)}>
      <div>{props.name}</div>
      <div className={classes.grow} />
      {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
    </div>
    <Collapse in={open} className={classes.collapse}>
      {
        props.array.map(r => {
          return <Link to={r.url} style={{textDecoration: 'none', color: 'black',fontFamily: "Poppins",}}>
            <Button className={classes.divCollapseElement}
                    fullWidth={true}
                    style={{justifyContent: "flex-start", textTransform: 'none', fontFamily: "Poppins",}}

            >
              {r.name}
            </Button>
          </Link>
        })
      }
    </Collapse>
  </ListItem>
}

export default HeaderRight
