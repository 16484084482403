/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"

import Header from "./header/header"
import "./layout.css"
import Footer from "./footer/footer"

import { ThemeProvider } from "@material-ui/core/styles"

import theme from "../theme"
import { Helmet } from "react-helmet"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import "react-multi-carousel/lib/styles.css"
import "react-alice-carousel/lib/alice-carousel.css"
import "../components/scrollbar.css"

const Layout = ({ children, subHeader = false }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Helmet>
        <meta charSet="UTF-8"/>
        <link
          rel="preload"
          href="https://fonts.gstatic.com/s/poppins/v15/pxiEyp8kv8JHgFVrJJnecnFHGPezSQ.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="" />
        <link
          rel="preload"
          href="https://fonts.gstatic.com/s/poppins/v15/pxiEyp8kv8JHgFVrJJfecnFHGPc.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="" />
        <link
          rel="preload"
          href="https://fonts.gstatic.com/s/poppins/v15/pxiEyp8kv8JHgFVrJJnecnFHGPezSQ.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="" />
        <link
          rel="preload"
          href="https://fonts.gstatic.com/s/poppins/v15/pxiEyp8kv8JHgFVrJJfecnFHGPc.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="" />
        <link rel="preload"
              as="style"
              href="https://fonts.googleapis.com/css?family=Poppins&display=swap" />
        <link rel="stylesheet"
              href="https://fonts.googleapis.com/css?family=Poppins&display=swap" />
        <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-NLNVRVK');`}</script>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Header siteTitle={data.site.siteMetadata?.title || `Title`} style={{ fontFamily: "Poppins" }}
                transparent={subHeader} />
        <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NLNVRVK"
                          height="0" width="0" style={{display: 'none', visibility:'hidden'}} title={"Google Tag Manager"}></iframe></noscript>
        <div
          style={subHeader ? {
            margin: `0 auto`,
            fontFamily: "Poppins",
            //marginTop: -80
          } : {
            margin: `0 auto`,
            fontFamily: "Poppins"
          }}
        >
          <main>{children}</main>
        </div>
        <Footer />
      </ThemeProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
