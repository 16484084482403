import * as React from "react"
import { Link } from "gatsby"
import { makeStyles } from "@material-ui/core"
import { Helmet } from "react-helmet"

const HeaderLeft = () => {
  const classes = useStyles()

  return <>
    <Link to="/" className={classes.rootLink}>
      <div className={classes.imageRoot}>
        <img src="/images/logo_2.svg" height={60} width={180} className={classes.image} alt="Alphapro" />
      </div>
    </Link>
    <Helmet>
      <link
        rel="preload"
        as="image"
        href="/images/logo_2.svg"
      />
    </Helmet>
  </>
}

const useStyles = makeStyles(() => ({
  rootLink: {
    color: `white`,
    textDecoration: `none`,
    zIndex: 10
  },
  imageRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "content",
    height: 80
  },
  image: {
    marginBottom: 0
  }
}))

export default HeaderLeft
