import * as React from "react"
import { Link, makeStyles } from "@material-ui/core"
import FooterLink from "./footer-link"
import { References, Services } from "../header/header-right"

const useStyles = makeStyles((theme) => ({
  divAll: {
    width: "100%",
    fontFamily: "Poppins",
    backgroundColor: "#11132B",
  },
  divGradient: {
    backgroundImage: "linear-gradient(to right, #00ACC8 , #00BC74)",
    height: 7,
    width: "100%"
  },
  divFooterContent: {
    width: "100%",
    height: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: 20,
    paddingBottom: 20,
    [theme.breakpoints.down(900)]: {
      flexWrap: "wrap",
      justifyContent: "center",
    },
    maxWidth: 1200,
    margin: 'auto'
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    [theme.breakpoints.down(900)]: {
      justifyContent: "center",
    },
  },
  infos: {
    color: "#6E7986",
    [theme.breakpoints.down(900)]: {
      textAlign: "center"
    }
  },
  column: {
    width: "25%",
    color: "white",
    fontSize: 14,
    minWidth: 220,
    [theme.breakpoints.down(900)]: {
      paddingTop: 5,
      paddingBottom: 5,
      textAlign: "center"
    }
  },
  columnTitle: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#00B790"
  },
  divLogoAndInfo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: '20px',
    [theme.breakpoints.down(900)]: {
      width: "90%",
      margin: 'auto',
      marginBottom: '30px',
    }
  },
  links: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    [theme.breakpoints.down(900)]: {
      flexWrap: "wrap",
      width: "90%",
      margin: 'auto'
    }
  }
}))

const Footer = () => {
  const classes = useStyles()
  return <div className={classes.divAll}>
    <div className={classes.divFooterContent}>
      <div className={classes.divLogoAndInfo}>
        <div className={classes.logo}>
          <Link href="/" aria-label={"alphaws"}>
            <img src="/images/logo_4.svg" alt={"alphaws"} height={50} width={120} style={{ marginBottom: 10 }} />
          </Link>
        </div>
        <div className={classes.infos}>
          <div>Alpha ⓒ 2022</div>
          <div>www.alphapro.hu</div>
          <div>Digitális megoldások 2008 óta</div>
        </div>
      </div>
      <div className={classes.links}>
        <div className={classes.column}>
          <div className={classes.columnTitle}>Rólunk</div>
          <FooterLink name="Bemutatkozás" link="/bemutatkozas" />
          <FooterLink name="Elérhetőségek" link="/kapcsolat" />
          <FooterLink name="Kérjen ajánlatot!" link="/kapcsolat" />
        </div>
        <div className={classes.column}>
          <div className={classes.columnTitle}>Szolgáltatások</div>
          {
            Services.map((s,i) => {
              return <FooterLink name={s.name} link={s.url} key={i}/>
            })
          }
        </div>
        <div className={classes.column}>
          <div className={classes.columnTitle}>Referenciák</div>
          {
            References.map((r,i) => {
              return <FooterLink name={r.name} link={r.url} key={i}/>
            })
          }
        </div>
        {/*<div className={classes.column}>
          <div className={classes.columnTitle}>Hírek</div>
        </div>*/}
      </div>
    </div>
    <div className={classes.divGradient} />
  </div>
}

export default Footer
