import * as React from "react"
import PropTypes from "prop-types"
import HeaderLeft from "./header-left"
import HeaderRight from "./header-right"
import { AppBar, makeStyles } from "@material-ui/core"
import { useEffect, useState } from "react"

const useStyles = makeStyles((theme) => ({
  base: {
    margin: `0 auto`,
    maxWidth: 1200,
    display: "flex",
    height: 80,
    paddingRight: 10
  },
  header: {
    backgroundImage: "linear-gradient(to right, #40A166 , #349E87)"
  },
  transparentHeader: {
    background: "rgba(0, 0, 0, 0.2)"
  },
  grow: {
    flex: 1
  }
}))


const Header = ({ siteTitle, transparent = true }) => {
  const classes = useStyles()
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    window.onscroll = () => {
      setOffset(window.pageYOffset)
    }
  }, []);

  return <AppBar position={"fixed"} style={offset < 40 && transparent? {backgroundColor: 'transparent'} : {}}>
    <header
      className={transparent ? classes.transparentHeader : classes.header}
    >
      <div className={classes.base}>
        <HeaderLeft siteTitle={siteTitle} />
        <div className={classes.grow} />
        <HeaderRight />
      </div>
    </header>
  </AppBar>
}

Header.propTypes = {
  siteTitle: PropTypes.string
}

Header.defaultProps = {
  siteTitle: ``
}

export default Header
