import * as React from "react"
import { withStyles } from "@material-ui/core/styles"
import { Button } from "@material-ui/core"

const AlphaButton = withStyles({
  root: {
    backgroundColor: "#303B56",
    width: 'fit-content',
    borderRadius: 25,
    paddingTop: 9,
    paddingBottom: 9,
    paddingLeft: 40,
    paddingRight: 40,
    color: "white",
    transition: "all 0.2s",
    "&:hover": {
      backgroundColor: "#303B56",
      transform: "perspective(1px) scale(1.05)"
    },
    textTransform: 'none'
  },
})(Button);

export default AlphaButton;
