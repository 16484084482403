import { createTheme } from '@material-ui/core/styles';

declare module '@material-ui/core/styles/createTheme' {
  interface Theme {
    custom: {
      pageMaxWidth: number;
      secondaryFontFamily: string;
    };
  }
  interface ThemeOptions {
    custom: {
      pageMaxWidth: number;
      secondaryFontFamily: string;
    };
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#00B790',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#11132B',
      dark: '#e2e2e2',
      contrastText: '#747474',
    },
    error: {
      main: '#EB002F',
    },
    background: {
      default: '#F3F2F4',
      paper: '#FFFFFF',
    },
    text: {
      primary: '#2d2d2d',
      secondary: '#3f3f3f',
    },
    success: {
      main: '#0D7C1B',
    },
    info: {
      main: '#707cb9',
      dark: '#3764f6',
    },
  },
  typography: {
    fontFamily: [
      'Poppins',
      'Helvetica',
      '-apple-system',
      'sans-serif',
    ].join(','),
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  custom: {
    pageMaxWidth: 1200,
    secondaryFontFamily: 'Roboto',
  },
});

export default theme;
